<template>
	<v-card elevation="1" v-if="isLocation">
		<v-card-title
			class="text-subtitle-1 font-weight-bold"
			:class="$vuetify.theme.dark ? 'secondary darken-1' : 'grey lighten-3 grey--text text--darken-1'"
		>
			<v-icon
				small
				left
				:class="$vuetify.theme.dark ? 'secondary darken-1' : ' grey--text text--darken-1'"
				v-text="'fas fa-tasks'"
			></v-icon>
			Lokasyonlar
			<v-spacer></v-spacer>
			<page-form
				@save-data="save"
				:form-data.sync="formData"
				@get-data="addItem"
				:user-data="companyUsers"
				ref="form"
			></page-form>
		</v-card-title>
		<v-card-text class="pa-0">
			<v-data-table
				:headers="headers"
				:options.sync="options"
				:items="locationGridData"
				:server-items-length="locationGridDataTotalCount"
				class="elevation-0"
			>
				<template v-slot:[`item.actions`]="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
								<v-icon x-small> fa-edit </v-icon>
							</v-btn>
						</template>
						<span>Düzenle</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon @click="deleteItem(item)" v-bind="attrs" v-on="on">
								<v-icon x-small> fa-trash </v-icon>
							</v-btn>
						</template>
						<span>Listeden Kaldır</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
	<v-card v-else>
		<v-card-text>Lokasyonlar aktif değil</v-card-text>
	</v-card>
</template>

<script>
import EventBus from '@/event-bus';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import {
	FETCH_SITE_SETTINGS,
	LOCATION_GRID_DATA,
	LOCATION_REMOVE_LISTED,
	LOCATION_GET_PRESET,
	LOCATION_ADD,
} from '@/store/modules/company.module';
import { mapActions, mapGetters } from 'vuex';

import PageForm from './Form.vue';

export default {
	name: 'CompanyLocation',
	components: {
		PageForm,
	},
	data() {
		return {
			options: {
				itemsPerPage: 10,
				page: 1,
				sortBy: [],
				sortDesc: [true],
				mustSort: true,
			},
			headers: [
				{ text: 'ID', value: 'id', align: 'left' },
				{ text: 'Adı', value: 'name', align: 'left' },
				{ text: 'Adres', value: 'address', align: 'left' },
				{ text: 'Telefon', value: 'phone', align: 'left' },
				{ text: 'Email', value: 'email', align: 'left' },
				{ text: 'İşlemler', value: 'actions', sortable: false, align: 'center' },
			],
			isLocation: false,
			dialog: false,
			formData: {
				id: 0,
				name: null,
				address: null,
				phone: null,
				email: null,
				users: [],
				defaultLocation: false,
			},
		};
	},
	computed: {
		...mapGetters(['settings', 'locationGridData', 'locationGridDataTotalCount', 'companyUsers']),
		initialized() {
			return this.cmsSiteSettings !== null;
		},
	},
	created() {
		this.$nextTick(() => {
			EventBus.$on('refreshRequestGrid', () => {
				this.componentKey += 1;
			});
		});
	},
	beforeDestroy() {
		EventBus.$off('refreshRequestGrid');
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings();
		});
	},
	methods: {
		...mapActions({
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchGridData: LOCATION_GRID_DATA,
			removeList: LOCATION_REMOVE_LISTED,
			getLocationPreset: LOCATION_GET_PRESET,
			saveLocation: LOCATION_ADD,
		}),
		deleteItem(item) {
			this.setMessageDialog({
				messageType: 'warning',
				subTitle: 'Emin misiniz?',
				text: 'Listeden kaldırmak istediğinize emin misiniz?',
				closeButtonText: 'Vazgeç',
				buttons: [
					{
						text: 'Evet',
						color: 'teal',
						action: () =>
							this.removeList(item).then(() => {
								this.disposeMessageDialog();
							}),
					},
				],
			});
		},
		addItem() {
			this.getLocationPreset(this.formData).then(() => {
				this.dialog = true;
			});
		},
		editItem(item) {
			this.getLocationPreset(item).then((response) => {
				this.formData = response.companyLocation;
				this.$refs.form.open();
			});
		},
		phoneNumberErrors() {},
		save() {
			const payload = this.formData;
			this.saveLocation(payload).then(() => {
				this.setMessageDialog({
					messageType: 'info',
					subTitle: 'Başarılı',
					text: 'Değişiklikler başarıyla güncellenmiştir.',
				});

				const newPayload = {
					...payload,
					pageSize: this.options.itemsPerPage,
					pageNumber: 1,
				};
				this.fetchGridData(newPayload);
			});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.isLocation = company.cmpIsLocation;
			},
			deep: true,
		},
		options: {
			handler(value) {
				const { sortBy, sortDesc } = value;
				const sortField = sortBy[0] ?? 0;
				const fieldIndex = this.headers.findIndex((x) => x.value === sortField);
				const orderByFieldId = fieldIndex < 0 ? 0 : fieldIndex + 1;
				const orderByDesc = sortDesc[0] ?? true;

				const payload = { ...this.$refs.filterRef?.filter };

				const newPayload = {
					...payload,
					pageSize: value.itemsPerPage,
					pageNumber: value.page,
					orderByFieldId,
					orderByDesc,
				};
				this.fetchGridData(newPayload);
			},
		},
	},
};
</script>

<style></style>
