var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":_vm.addItem}},'v-btn',attrs,false),on),[_vm._v(" Yeni Ekle ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Lokasyon Ekle")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"color":"teal","dense":"","outlined":"","hide-details":"auto","label":"Lokasyon adı","error-messages":_vm.nameErrors()},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"color":"teal","dense":"","outlined":"","hide-details":"auto","autocomplete":"Lokasyon adresi","label":"Lokasyon adresi","error-messages":_vm.addressErrors()},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field-simplemask',{attrs:{"label":'Telefon Numarası',"properties":{
									color: 'teal',
									inputmode: 'numeric',
									prefix: '+90',
									placeholder: '(555)-555-55-55',
									dense: true,
									outlined: true,
									class: 'rounded-lg',
									errorMessages: _vm.phoneNumberErrors(),
									hideDetails: 'auto',
									flat: true,
								},"options":{
									inputMask: '(###)-###-##-##',
									outputMask: '##########',
									empty: null,
									applyAfter: false,
									alphanumeric: true,
									lowerCase: false,
								}},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"color":"teal","dense":"","outlined":"","hide-details":"auto","label":"Email"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"lg":"12","cols":"12"}},[_c('v-checkbox',{attrs:{"color":"teal","label":"Varsayılan Lokasyon","hide-details":"auto"},model:{value:(_vm.formData.defaultLocation),callback:function ($$v) {_vm.$set(_vm.formData, "defaultLocation", $$v)},expression:"formData.defaultLocation"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_vm._v(" Lokasyon Kullanıcılarını Seçin "),_c('v-combobox',{attrs:{"items":_vm.users,"multiple":"","item-text":"value","item-value":"id","chips":"","deletable-chips":""},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Kapat ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","dark":!_vm.$v.formData.$invalid,"disabled":_vm.$v.formData.$invalid},on:{"click":_vm.save}},[_vm._v(" Kaydet ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }