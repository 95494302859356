<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="600px">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="primary" dark v-bind="attrs" v-on="on" @click="addItem"> Yeni Ekle </v-btn>
			</template>
			<v-card>
				<v-card-title>
					<span class="text-h5">Lokasyon Ekle</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" sm="12">
								<v-text-field
									color="teal"
									dense
									outlined
									hide-details="auto"
									label="Lokasyon adı"
									v-model="formData.name"
									:error-messages="nameErrors()"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="12">
								<v-textarea
									color="teal"
									dense
									outlined
									hide-details="auto"
									autocomplete="Lokasyon adresi"
									label="Lokasyon adresi"
									v-model="formData.address"
									:error-messages="addressErrors()"
								></v-textarea>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field-simplemask
									v-model="formData.phone"
									v-bind:label="'Telefon Numarası'"
									v-bind:properties="{
										color: 'teal',
										inputmode: 'numeric',
										prefix: '+90',
										placeholder: '(555)-555-55-55',
										dense: true,
										outlined: true,
										class: 'rounded-lg',
										errorMessages: phoneNumberErrors(),
										hideDetails: 'auto',
										flat: true,
									}"
									v-bind:options="{
										inputMask: '(###)-###-##-##',
										outputMask: '##########',
										empty: null,
										applyAfter: false,
										alphanumeric: true,
										lowerCase: false,
									}"
								/>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field
									color="teal"
									dense
									outlined
									hide-details="auto"
									label="Email"
									v-model="formData.email"
								></v-text-field>
							</v-col>
							<v-col lg="12" cols="12" class="pt-0">
								<v-checkbox
									color="teal"
									v-model="formData.defaultLocation"
									label="Varsayılan Lokasyon"
									hide-details="auto"
								></v-checkbox>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="12">
								Lokasyon Kullanıcılarını Seçin
								<v-combobox
									:items="users"
									v-model="selectedUser"
									multiple
									item-text="value"
									item-value="id"
									chips
									deletable-chips
								></v-combobox>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close"> Kapat </v-btn>
					<v-btn
						color="blue darken-1"
						text
						:dark="!$v.formData.$invalid"
						@click="save"
						:disabled="$v.formData.$invalid"
					>
						Kaydet
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import locationValidationMixin from './locationValidationMixin';

export default {
	name: 'PageForm',
	mixins: [validationMixin, locationValidationMixin],
	props: {
		formData: {
			required: true,
		},
		userData: {
			required: true,
			type: Array,
		},
	},
	data() {
		return {
			dialog: false,
			selectedUser: [],
		};
	},
	computed: {
		users() {
			return this.userData;
		},
	},
	mounted() {
		this.setSelectedUser();
	},
	watch: {
		selectedUser: {
			handler() {
				this.formData.users = this.selectedUser.map((x) => x.id);
			},
		},
		dialog: {
			handler() {
				this.setSelectedUser();
			},
		},
	},
	methods: {
		save() {
			this.$emit('save-data');
			this.close();
		},
		addItem() {
			this.$emit('get-data');
		},
		close() {
			this.$emit('update:form-data', {
				id: 0,
				name: null,
				address: null,
				phone: null,
				email: null,
				users: [],
			});
			this.dialog = false;
		},
		open() {
			this.dialog = true;
		},
		setSelectedUser() {
			if (this.dialog) this.selectedUser = this.users.filter((x) => this.formData.users.includes(x.id));
		},
	},
};
</script>

<style></style>
